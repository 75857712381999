import React from "react"
import {Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Row, Col, Divider, Tooltip} from "antd";
import {utils} from "../services/utils";

const SponsorsPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sp">
                <div className="container text-center pt-4">
                    <h1>Gli sponsor</h1>
                    <h5 className="text-uppercase text-primary fw-bold">Fai click su un logo per scoprirne di più!</h5>
                </div>
                <section className="pt-4">
                    <div className="container">
                        <Row gutter={24} type="flex">
                            <Col xs={24} className="text-center mb-4">
                                <div className="mt-5 text-center">
                                    <Row type="flex" align="middle">
                                        {utils.sponsors.map(sponsor => {
                                            return (
                                                <Col xs={8} lg={4} className="px-lg-3 mb-4">
                                                    <h6 className="mb-0 text-uppercase text-primary fw-bold">Buca {sponsor.order}</h6>
                                                    <Link to={sponsor.url}>
                                                        <Tooltip title="Fai click per scoprire di più">
                                                            <img src={sponsor.logo} alt={sponsor.name}
                                                                 className="sp-logo"/>
                                                        </Tooltip>
                                                    </Link>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorsPage
